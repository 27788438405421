import React from 'react'

function Footer(props) {
  return (
    <div className="{&quot;bg-gray-900" text-white="" p-6="" text-center="" text-sm="" "="" +="" props.transition}="">
  &copy; <span className="">AF FILMS</span> 2024 &bull; <a className="link" href="/legal">Legal</a> <br> <a style="{{color:'#777'}}" className="text-sm pb-2" href="https://remiblot.com">Crafted by Rémi Blot</a>
  <br>

</div>
  )
}

export default Footer
