import React from 'react'
import { useRouteError, Link } from "react-router-dom";
import TitleAnimated from './TitleAnimated'

export default function ErrorPage(props) {
  const error = useRouteError();
  return (
    <section className="{&quot;pt-24" "="" +="" props.transition}="">
        <div className="px-12 mx-auto max-w-7xl">

      {error.status == "404" ?     <div className="w-full mx-auto text-center md:w-11/12 xl:w-9/12 md:text-center">
        <titleanimated level="{1}" id_t="{'project_title'}" classes="{'proj-title" text-center="" pb-6="" lg:pt-31="" xl:pt-80="" pb-4="" text-title="" lg:text-title_lg="" xl:text-title_lg="" leading-6*2'}="" letterSpeed="{0.02}" scale="{1.0}" color="white" title="ERROR 404" afterTitle="{true}"></titleanimated>
        <div className="afterTitle opacity-0">

              <p className="px-0 mb-8 text-lg text-white md:text-xl lg:px-24">
              <span>No encontramos la p&aacute;gina<br><small>no es disponible o se ha eliminada... <br>Lo sentimos.</small></span>
              </p>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                  <link to="/" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-magenta rounded-xl sm:w-auto sm:mb-0">
                      Volver a la p&aacute;gina principal
                      <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    
                  </div>

              </div>
          </div> : ''}


    </div>
    </section>
  );
}
